@import-normalize;

@font-face {
  font-family: 'Manrope';
  src: local('Manrope'), url('/assets/fonts/Manrope-Regular.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Manrope';
  src: local('Manrope'), url('/assets/fonts/Manrope-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Manrope';
  src: local('Manrope'), url('/assets/fonts/Manrope-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Manrope';
  src: local('Manrope'), url('/assets/fonts/Manrope-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
}

#root {
  height: 100%;
  width: 100%;
}

.flag-dropdown .selected-flag {
  background-color: transparent !important;
}

.ql-container {
  &.ql-snow {
    color: #101010;
    border-radius: 16px;
    border: 1px solid #e0e0f0;
  }
}
