.inputContainer {
  font-size: 1rem !important;

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.inputButton {
  background-color: #F3F5FA !important;
  border: 1px solid #F3F5FA !important;
  border-radius: 12px !important;
}

.inputPhone {
  width: calc(100% - 48px) !important;
  background-color: #F3F5FA !important;
  padding: 14px !important;
  float: right;
  border: 1px solid #F3F5FA !important;
  border-radius: 12px !important;
  line-height: inherit !important;
  font-size: inherit !important;
  height: auto !important;
}

.inputError {
  border: 1px solid #FE5249 !important;
}

.inputDropdown {
  background-color: #F3F5FA !important;
  border: 1px solid #F3F5FA !important;
  border-radius: 12px !important;
  width: 280px !important;
}

.inputSearch {
  padding: 14px !important;
  box-shadow: 0 0 3px rgb(0 0 0 / 30%);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  input {
    flex: 1;
  }
}
